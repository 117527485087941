
.pop-up {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    
    
}

.inner-pop-up {
    top: 15%;
    position: relative;
    padding: 2rem;
    margin-top: auto;
    margin-bottom: 25rem;
    width: 60%;
    height: auto;
    background-color: white;
    border-radius: .5rem;
    box-shadow:  5px 5px, rgba(45, 243, 13, 0.3) 10px 10px, rgba(45, 243, 13, 0.2) 15px 15px, rgba(45, 243, 13, 0.1) 20px 20px, rgba(45, 243, 13, 0.05) 25px 25px;
    
}

.close-popup {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
.close-popup:hover{
    color:black;
}

.form-container {
   width: 100%; 
   padding-top: 3em; 
}
.form-container-h1 {
    
    display: flex;
    width: 80%;
}

.form-headline{
    font-family: 'Roboto mono', monospace;
    font-weight:400;
}

.form label {
    display: block;
    padding-bottom: .5em;
    padding-top: 1em;
    font-family: 'Silkscreen', cursive;
    font-size: 1.5rem;
}

.form input {
    height: 3em;
    width: 100%;
    font-size: 1.2rem;
}

.form textarea {
    width: 100%;
    height: 7em;
    font-size: 1rem;
}

input:focus { 
    outline: none !important;
    border-color:rgba(45, 243, 13);
    box-shadow: 0 0 10px #2df30d;
}
textarea:focus { 
    outline: none !important;
    border-color:rgba(45, 243, 13);
    box-shadow: 0 0 10px rgba(45, 243, 13);
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:1.5em;
}

.submit-button {
    width: 6rem;
    height: 2rem;
    border-radius: .3rem;
    letter-spacing: .25rem;
    border: none;
    color: white;
    background-color: black;
    box-shadow: inset 0 0 0 0 #2df30d;
    transition: ease-out .3s;
    font-size: 1rem;
    outline: none; 
    cursor: pointer;  
}

.submit-button:hover {
    box-shadow: inset 6rem 0 0 0 #2df30d;

}
.About-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 2em;
}

.about-title {
    font-family:'Silkscreen', cursive ;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.about-info {
    font-family: 'Roboto mono', monospace;
    font-size: 1.2rem;
    line-height: 2rem ;
    margin-top: 0 ;
}

@media(max-width:540px)  {
  
    .inner-pop-up {
        top:5%;
        width: 90%;
        box-shadow: none;
        margin-top:auto;
        margin-bottom: 25rem;    
    }
    
    .about-title {
        font-size: 2rem;
    }

    .About-container {
        text-align: center;   
    }

    .pop-up {
        background-color: rgba(0,0,0,.8)
    }
}